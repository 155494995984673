// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    TextField
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Delete as RemoveIcon, 
    CloudDownload as DownloadIcon,
    CloudUpload as UploadIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default (props) => {
    return (
        <React.Fragment>       
            <FormControl                 
                fullWidth={true}
                hiddenLabel={true}
            >
                {/* <FormLabel id={`${props.type.fields[props.fieldId]}-label`}>
                    {props.type.fields[props.fieldId].title}
                </FormLabel> */}
            <ButtonGroup variant="contained"
                fullWidth
                disableElevation
            >                    
                <TextField
                    // aria-labelledby={`${props.type.fields[props.fieldId]}-label`}
                    label={props.type.fields[props.fieldId].title}
                    // value={(props.data[props.fieldId]) ? props.meta.originalname : "Choose file to upload ..."}
                    // value={`${props.meta.originalname}`}
                    value={(props.data[props.fieldId]) ? `${props.meta.originalname || ""}` : ""}
                    InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    //   disabled
                    
                ></TextField>
                {/* <Button disabled style={{justifyContent: "flex-start"}}>
                    {(props.data[props.fieldId]) ? props.meta.originalname : "Choose file to upload ..."}
                </Button> */}
                {(!props.data[props.fieldId])
                &&             
                <label htmlFor={props.fieldId +"-file"}>
                    <LoadingButton 
                        component="span"
                        color={'success'}                            
                        sx={{
                                width: "30px",
                                height: "100%",
                        }}           

                        loading={(props.uploadProgress > 0 && props.uploadProgress < 100)}                                                
                        disabled={(props.formState.locked || props.formState.disabled)}
                    >
                        <UploadIcon />                            
                    </LoadingButton>        
                    </label>
                }
                {(props.data[props.fieldId])
                &&
                    <Button                         
                        color={'primary'}                             
                        sx={{
                            width: "30px"
                        }}   
                        
                        disabled={(props.formState.locked || props.formState.disabled || props.meta.fileNotFound || (props.uploadProgress > 0 && props.uploadProgress < 100))}
                        onClick={() => {props.onDownloadFile (null)}}
                    >
                        <DownloadIcon />
                    </Button>
                }
                {(props.data[props.fieldId])
                &&
                    <Button                         
                        color={'error'}                             
                        sx={{
                            width: "30px"
                        }}   
                        
                        disabled={(props.formState.locked || props.formState.disabled || (props.uploadProgress > 0 && props.uploadProgress < 100))}
                        onClick={() => {props.onRemoveFile (null)}}
                    >
                        <RemoveIcon />
                    </Button>
                }
            </ButtonGroup>
            </FormControl>
        </React.Fragment>
    )    
}