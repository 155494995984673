// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from "react-router-dom"
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,
    Stack,
    DialogContentText,    
    Button,
    IconButton,
    Collapse,
    Alert,
    Grid,
    Typography,
    Box,
    CircularProgress
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Refresh as RefreshIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    Close as CloseIcon,
    Save as SaveIcon,
    Visibility as ShowIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import { isAdministrator, API_URL_PHONEZOLUTIONS, API_URL_SIMPLECRM } from '../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
    SimpleUIDialogListSelector,

    SimpleUICommonUseInterval
} from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import RenderField from './components/renderField'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_SIMPLEDATA = "/api/simplecrm/entities/"
const API_URL_SIMPLEDATASCHEMA = "/api/simplecrm/entityschemas/"
const API_URL_SIMPLEDATATYPE = "/api/simplecrm/entitytypes/"
// ----------------------------------------------------------------------

const MODE = {
    CREATE: 0,
    CREATE_WITH_TYPE: 1,    
    EDIT: 2
}

export default (props) => {
    const { user, accessToken } = SimpleUIAuthState()
    const params = useParams()
    const navigate = useNavigate();
    
    const formStateDefault = {
        isInitializing: true,

        mode: "",
 
        mainButtonDisabled: true,
        mainButtonLoading: false,

        disabled: false,
        locked: false,

        warning: false,
        warningText: "",

        error: false,
        errorText: "",

        fieldError: {}
    }

    const [formState, setFormState] = useState(formStateDefault)    

    const [selectorOpenAction, setSelectorOpenAction] = React.useState(false)

    const [type, setType] = useState(false)
    const [entityDefaultData, setDefaultEntityData] = useState({})
    const [entityData, setEntityData] = useState({})

    const [layout, setLayout] = useState([])

    useEffect(async () => {
        let mode = null

        if (params.entityId == "00000000-0000-0000-0000-000000000000" && props.typeId)
            mode = MODE.CREATE_WITH_TYPE
        else if (params.entityId == "00000000-0000-0000-0000-000000000000")
            mode = MODE.CREATE
        else if (params.entityId)
            mode = MODE.EDIT

        switch (mode) {
            // CREATE
            case 0: {
                setSelectorOpenAction(true)
                break
            }

            // CREATE WITH TYPE
            case 1: {
                try {
                    let getType = await fetch(API_URL_SIMPLEDATATYPE + props.typeId, {
                        method: 'GET',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer "+ accessToken
                        }            
                    })     
                
                    if (!getType.ok)
                        throw new Error((await getType.json()).error.code)
                
                    setType(await getType.json())
                    setEntityData({})
                    setDefaultEntityData({})

                    setFormState({ ...formState, isInitializing: false, mode: "create" })
                } catch (error) {
                    handleError(error)
                    setFormState({ ...formState, isInitializing: false, locked: true, mode: "loadError" })
                }
                
                break
            }

            // EDIT
            case 2: {
                try {
                    let locked = await handleLock()
                    
                    let getEntity = await fetch(API_URL_SIMPLEDATA + params.entityId, {
                    method: 'GET',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer "+ accessToken
                        }            
                    })           
        
                    if (!getEntity.ok)
                        throw new Error((await getEntity.json()).error.code)

                    let entity = await getEntity.json()

                    if (props.typeId && (entity.type.id != props.typeId))
                        console.log ("!!!!")
                            
                    setType(entity.type)
                    setEntityData(entity.data)
                    setDefaultEntityData(entity.data)
                    setFormState({ ...formState, isInitializing: false, locked: !locked, mode: "edit" })
                } catch (error) {
                    handleError(error)
                    setFormState({ ...formState, isInitializing: false, locked: true, mode: "loadError" })
                }            

                break
            }
        }        
    }, [])

    useEffect(async () => {        
        if (type) {
            if (type.layouts.edit) {
                setLayout(structuredClone(type.layouts.edit))
            } else {
                let layout = [
                    {
                        id: "",
                        title: "",
                        content: [{
                            size: 12,
                            fields: []
                        }]
                    }
                ]                                
            
                Object.keys(type.fields).forEach(fieldId => {
                    layout[0].content[0].fields.push(fieldId)                    
                })

                setLayout(structuredClone(layout))
            }
        }        
    }, [type])

    SimpleUICommonUseInterval(async () => {
        // console.log ("Mode:"+ formState.mode)
        if (formState.mode == "edit") {
            if (await handleLock()) {
                if (formState.locked)
                    setFormState({ ...formState, isInitializing: false, locked: false })
            } else {            
                if (!formState.locked)
                    setFormState({ ...formState, isInitializing: false, locked: true })                
            }
        }
    }, 10000)

    useEffect(() => {
        if (formState.locked)
            handleWarning ("This "+ type.name +" has been locked by another user. Lock will be acquired when released.")
        else 
            handleWarning ()
    }, [formState.locked])
    
    useEffect(() => {
        let formValid = 0

        // Validate: CHANGED
        // console.log (entityData)
        // console.log (entityDefaultData)
        if (JSON.stringify(entityDefaultData) === JSON.stringify(entityData)) {
            formValid++
        }
        
        // Validate: REQUIRED FIELDS        
        // if (type.schema) {
            for (var fieldId in type.fields) {
                const field = type.fields[fieldId]                
                
                // console.log (field)

                if (field.required) {                    
                    if (!entityData.hasOwnProperty(fieldId)) {
                        formValid++
                        continue
                    }
                        
                    switch (field.type) {
                        case "select": {
                            if (Array.isArray(entityData[fieldId]) && entityData[fieldId].length == 0) {
                                formValid++
                            } else if (entityData[fieldId] instanceof Object) {
                                formValid++
                                for (var option in entityData[fieldId]) {
                                    if (entityData[fieldId][option]) {
                                        formValid--
                                        break
                                    }
                                }
                            } else if (entityData[fieldId] == "") {
                                formValid++
                            }                           
                            break
                        }

                        case "upload": {
                            break
                        }

                        default: {
                            if (entityData[fieldId] == "")
                                formValid++
                        }
                    }
                }
            }
        // }
        // console.log (formValid)       

        setFormState({...formState, 
            error: false,    
            errorText: "",           
            disabled: false, 
            mainButtonLoading: false,
            mainButtonDisabled: !!(formValid)            
        })        
    }, [entityData])

    const handleLock = async (entityId) => {
        if (!entityId)
            entityId = params.entityId
        
        let getLock = await fetch(API_URL_SIMPLEDATA + entityId, {
            method: 'LOCK',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })  
            
        if (getLock.ok)
            return true

        return false
    }

    const handleUnlock = async () => {
        let getLock = await fetch(API_URL_SIMPLEDATA + params.entityId, {
            method: 'UNLOCK',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })                
    }

    const handleCreate = async () => {
        setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        try {            
            let postEntity = await fetch(API_URL_SIMPLEDATA, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify({
                    typeId: type.id,
                    data: entityData,
                })
            })

            if (!postEntity.ok)
                throw new Error(await postEntity.text())
            
            let newEntity = await postEntity.json()

            setDefaultEntityData(newEntity.data)
            setEntityData(newEntity.data)

            await handleLock(newEntity.id)

            if (props.typeId && (((type || {}).layouts || {}).navigation || {}).editRoute) 
                navigate(type.layouts.navigation.editRoute.replace(":entityId", newEntity.id))
            else
                navigate('/entities/'+ newEntity.id)
        } catch (error) {                      
            handleError(error)
        }        
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        try {
            let patchEntity = await fetch(API_URL_SIMPLEDATA + params.entityId, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(
                    {data: entityData}
                )
            })  

            if (!patchEntity.ok)
                throw new Error(await patchEntity.text())
                        
            setDefaultEntityData({...entityData})
            setEntityData({...entityData})
        } catch (error) {
            handleError(error)
        }        
    }

    const handleClose = async () => {
        await handleUnlock()

        // if (props.typeId && (((type || {}).layouts || {}).navigation || {}).editRoute) 
        if (type?.navigation?.url)
            navigate(`${type.navigation.url}/`)
        else
            navigate('/') 
            // navigate("/entities/")
    }

    const handleOnChange = (event) => {
        const id = (event.target.name || event.target.id)
        const value = event.target.value


        if ((formState.fieldError[id] || {}).error) {
            setFormState({...formState, 
                fieldError: {}
            })
        }

        // console.log(id + ":" + value)

        setEntityData(prevState => {
            let newState = { ...prevState, [id]: value }
            return newState
        })

        return true
    }

    const handleWarning = (message) => {
        if (message)
            setFormState({ ...formState, warning: true, warningText: message })
        else
            setFormState({ ...formState, warning: false, warningText: message })
    }

    const handleError = (error) => {
        error = (JSON.parse(error.message)).error
        
        switch (error.code) {
            case "ER_ENTITY_IS_LOCKED_BY_OTHER_USER":
                setFormState({ ...formState, error: true, errorText: "This entity is edited by another user." })
                break
            case "ER_ENTITYTYPE_NOT_FOUND":
                setFormState({ ...formState, error: true, errorText: "EntityType not found." })
                break

            case "ER_ENTITY_NOT_FOUND":
                setFormState({ ...formState, error: true, errorText: "Entity not found." })
                break

            case "ER_ENTITY_DATA_ERROR": {                
                let messsage = JSON.parse(error.message)
                let fieldError = {}                
                fieldError[messsage.key] = {error: true, code: messsage.error.code, text: messsage.error.code}                

                setFormState({ ...formState, fieldError: fieldError})
                break
            }
            
            default:
                setFormState({ ...formState, error: true, errorText: "Unexpected error occured." })
        }
        // console.log (error.message)        
    }

    const handleFetchTypes = async () => {


        let getTypes = await fetch(API_URL_SIMPLEDATATYPE, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })     

        if (!getTypes.ok)
            throw new Error((await getTypes.json()).error.code)
        
        return await getTypes.json()
    }

    const handleSelectType = async (data) => {
        try {
            let getType = await fetch(API_URL_SIMPLEDATATYPE + data[0], {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getType.ok)
               throw new Error((await getType.json()).error.code)

            setType(await getType.json())
            setFormState({ ...formState, isInitializing: false, mode: "create" })
        } catch (error) {
            handleError(error)
        }
    }

    const handleOnClickMainButton = () => {
        if ((params.entityId == "00000000-0000-0000-0000-000000000000"))
            handleCreate()
        else
            handleUpdate() 
    }

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <SimpleUIDialogListSelector 
                    open={selectorOpenAction}
                    setOpen={setSelectorOpenAction}
                    
                    title="Please select Type"
                    button1Text="Cancel"
                    button2Text="Select"

                    columns={[{ field: 'name', headerName: 'Title', flex: 1 }]}                    

                    fetchRows={handleFetchTypes}

                    onSelect={handleSelectType}
                    onCancel={handleClose}
                />   

                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    } 

    return (
        <React.Fragment>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100%)',
                    width: '100%'
                }}
            >
                <div>
                    <Collapse in={formState.error}>
                        <Alert variant="filled" severity="error" sx={{mb: 2}}
                            action={
                                <IconButton aria-label="close" color="inherit" size="small"
                                    onClick={() => {
                                        setFormState({ ...formState, error: false })
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {formState.errorText}
                        </Alert>
                    </Collapse>
                    <Collapse in={formState.warning}>
                        <Alert variant="filled" severity="warning" sx={{mb: 2}}
                            
                        >
                            {formState.warningText}
                        </Alert>
                    </Collapse>
                </div>

                <Stack spacing={2} direction="row" justifyContent="end" sx={{ pb: '20px' }}>                    
                    <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled || formState.locked} onClick={handleOnClickMainButton}>
                        {(params.entityId == "00000000-0000-0000-0000-000000000000") ? "Create" : "Save" }                                
                    </LoadingButton>  
                    
                    <Button variant="outlined"  aria-label="close" onClick={handleClose} disabled={formState.disabled}>
                        Close
                    </Button>
                </Stack>

                <Paper elevation={3} sx={{ p: 2, mb: 2, pb: "50px" }}>

                    {/* {type.layouts.edit.map((layout, layoutIndex) => ( */}
                    {layout.map((layout, layoutIndex) => (
                        <Grid container spacing={2}>

                            {layout.content.map((content, contentIndex) => (
                                <React.Fragment>
                                    <Grid item xs={content.size}>
                                        {contentIndex < 1 &&
                                            <Typography variant="h6" display="block" sx={{ mb: 0 }}>
                                                {layout.title}
                                            </Typography>
                                        }
                                        {content.fields.map((fieldId, fieldIndex) => (
                                            <Box sx={{
                                                pt: 1,                                                
                                            }}>
                                            <RenderField 
                                                fieldId={fieldId}                                                 
                                                type={type} 
                                                entityId={params.entityId} 
                                                data={entityData} 
                                                onChange={handleOnChange} 
                                                formState={formState}
                                            />
                                            </Box>
                                        ))}
                                    </Grid>
                                </React.Fragment>
                            ))}

                        </Grid>
                    ))}

                </Paper>
            </Paper>
        </React.Fragment>
    )
}
