// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
} from './../../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import File from './file'
import Files from './files'
import Image from './image'
import Images from './images'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_SIMPLEDATA = "/api/simplecrm/entities/"

export default (props) => {    
    const { accessToken } = SimpleUIAuthState()

    const [meta, setMeta] = useState({})  
    const [uploadProgress, setUploadProgress] = useState(0)

    useEffect(() => {
        if (props.data[props.fieldId]) {
            handleGetMeta()
            .then ((result) => {                
                setMeta(((Array.isArray(props.data[props.fieldId])) ? result : (result.find((e) => e.filename == props.data[props.fieldId] || {}))))
            })
            .catch((error) => {
                setMeta((Array.isArray(props.data[props.fieldId])) ? [] : {originalname: "Error: File could not be found.", filename: props.data[props.fieldId], fileNotFound: true})
            })
        }

        setUploadProgress(0)
    }, [props.data[props.fieldId]])      

    const handleUploadFile = async (event) => {
        setUploadProgress(1)
        if (event.target.files.length == 1) {
            const data = new FormData()
            data.append('FILE', event.target.files[0])

            fetch(`${API_URL_SIMPLEDATA}${props.entityId}/storage/${props.fieldId}/`, {
                method: 'POST',
                headers: {
                    'Authorization': "Bearer "+ accessToken
                },
                body: data
            })       
            .then(result => result.json ())
            .then(json => {
                handleOnChange({
                    value: ((Array.isArray(props.data[props.fieldId])) ? [...props.data[props.fieldId]].concat(json.storageId) : json.storageId)
                })
            })
            .catch(error => {
                handleError (error)
            })
        } else {
            let storageIds = []
            for (let i = 0; i < event.target.files.length; i++) {
                setUploadProgress(((100/event.target.files.length) * i))
    
                const data = new FormData()
                data.append('FILE', event.target.files[i])
                
                try {
                    let postStorage = await fetch(`${API_URL_SIMPLEDATA}${props.entityId}/storage/${props.fieldId}/`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        },
                        body: data
                    })
    
                    if (!postStorage.ok)
                        throw new Error((await postStorage.json()).error.code)
                
                    storageIds.push((await postStorage.json()).storageId)    
                } catch (error) {
                    handleError(error)
                } 
            }
    
            handleOnChange({
                value: [...props.data[props.fieldId]].concat(storageIds)
            })   

            setUploadProgress(0)
        }
    }

    const handleGetMeta = async () => {
        let getMeta = await fetch(`${API_URL_SIMPLEDATA}${props.entityId}/storage/${props.fieldId}/meta/`, {
            method: 'GET',
            cache: "no-store",
            headers: {
                'Authorization': "Bearer "+ accessToken
            }
        })

        if (!getMeta.ok)
            throw new Error((await getMeta.json()).error.code)                

        return await getMeta.json()            
    }

    const handleGetPreview = async (storageId) => {
        try {            
            let preview = await fetch(`${API_URL_SIMPLEDATA}${props.entityId}/storage/${props.fieldId}/preview/${storageId}`, {
                method: 'GET',
                cache: "no-store",
                headers: {
                    'Authorization': "Bearer "+ accessToken
                }
            })

            return URL.createObjectURL(await preview.blob())    
        } catch (error) {
            handleError (error)
        }
    }

    const handleRemoveFile = (storageId) => {
        let newData = ""
        if (storageId) {
            newData = props.data[props.fieldId].filter((id) => id != storageId)
            // setFiles([...newData])
        } 

        handleOnChange({
            value: newData
        }) 
    }

    const handleDownloadFile = async (storageId) => {
        if (!storageId)
            storageId = props.data[props.fieldId]

        let getMeta = await fetch(`${API_URL_SIMPLEDATA}${props.entityId}/storage/${props.fieldId}/meta/${storageId}`, {
            method: 'GET',                
            headers: {
                'Authorization': "Bearer "+ accessToken
            }
        })

        if (!getMeta.ok)
            throw new Error((await getMeta.json()).error.code)

        let meta = await getMeta.json()

        fetch(`${API_URL_SIMPLEDATA}${props.entityId}/storage/${props.fieldId}/download/${storageId}`, {
            method: 'GET',            
            headers: {
                'Authorization': "Bearer "+ accessToken
            }
        })
        .then(result => result.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.style.display = "none"
            a.href = url          
            a.download = meta.originalname
            document.body.appendChild(a)
            a.click()
            URL.revokeObjectURL(url)
        })
        .catch (error => {
            handleError (error)
        })
    }

    const handleOnChange = (event) => {
        if (props.onChange)
            props.onChange({target: {name: props.fieldId, value: event.value}})
    }

    const handleError = (error) => {
        console.log (error)        
    }

    switch (props.type.fields[props.fieldId].subType) {
        case "file": {
            return (
                <React.Fragment>
                    <input style={{ display: 'none' }}
                        
                        id={`${props.fieldId}-file`}                
                        type="file" 
                        accept={props.type.fields[props.fieldId].mimetypes.toString()}
                 
                        onChange={handleUploadFile} 
                        disabled={(props.formState.locked || props.formState.disabled)}
                    />
                
                    <File 
                        {...props}
                        
                        onGetPreview={handleGetPreview}
                        onDownloadFile={handleDownloadFile}
                        onRemoveFile={handleRemoveFile}              
                        onError={handleError}

                        meta={meta}
                        uploadProgress={uploadProgress}
                    />
                </React.Fragment>       
            )   
        }

        case "files": {
            return (
                <React.Fragment>
                    <input style={{ display: 'none' }} 
                        id={`${props.fieldId}-file`}
                        type="file" 
                        multiple
                        accept={props.type.fields[props.fieldId].mimetypes.toString()}                
                
                        onChange={handleUploadFile} 
                        disabled={(props.formState.locked || props.formState.disabled)}
                    />

                    <Files 
                        {...props}
                                                
                        onGetPreview={handleGetPreview}
                        onDownloadFile={handleDownloadFile}
                        onRemoveFile={handleRemoveFile}              
                        onError={handleError}

                        meta={meta}
                        uploadProgress={uploadProgress}
                    />   

                </React.Fragment>                
            )
        }
        
        case "image": {
            return (
                <React.Fragment>
                    <input style={{ display: 'none' }}
                        id={`${props.fieldId}-file`}                
                        type="file" 
                        accept={props.type.fields[props.fieldId].mimetypes.toString()}
                 
                        onChange={handleUploadFile} 
                        disabled={(props.formState.locked || props.formState.disabled)}
                    />

                    <Image
                        {...props}
                        
                        onGetPreview={handleGetPreview}
                        onDownloadFile={handleDownloadFile}
                        onRemoveFile={handleRemoveFile}              
                        onError={handleError}

                        meta={meta}
                        uploadProgress={uploadProgress}
                    />

               </React.Fragment>
            )
        }

        case "images": {
            return (
                <React.Fragment>
                    <input style={{display: 'none'}} 
                        id={`${props.fieldId}-file`}
                        type="file"
                        multiple 
                        accept={props.type.fields[props.fieldId].mimetypes.toString()}
                        
                        onChange={handleUploadFile}                
                        disabled={(props.formState.locked || props.formState.disabled)}
                    />
        
                    <Images 
                        {...props}
                        
                        onGetPreview={handleGetPreview}
                        onDownloadFile={handleDownloadFile}
                        onRemoveFile={handleRemoveFile}              
                        onError={handleError}

                        meta={meta}
                        uploadProgress={uploadProgress}
                    />                
                </React.Fragment>
            )
        }

        default: {
            return (<React.Fragment/>)
        }
    }    
}