// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// import {} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import String from './renderTypes/string'
import StringFormat from './renderTypes/stringFormat'
import Boolean from './renderTypes/boolean'
import Select from './renderTypes/select/'
import TextField from './renderTypes/textfield/'
import Upload from './renderTypes/upload/'
// import EntityList from './renderTypes/entityList'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {



    switch (props.type.fields[props.fieldId].type) {
        case "stringformat":
            return (<StringFormat {...props}/>)
        
        case "textfield":
            return (<TextField {...props}/>)

        

        // case "boolean":
        //     return (<Boolean {...props}/>)
            
        // case "select":
        //     return (<Select {...props}/>)

        // case "textfield":
        //     return (<TextField {...props}/>)
        
        case "upload":
            return (<Upload {...props}/>)

        // case "entitylist":
        //     return (
        //         <EntityList fieldId={props.fieldId} data={props.data} formState={props.formState}></EntityList>
        //     )

        default:
            return (
                <React.Fragment></React.Fragment>
            )
    }
}