// --------------------------------------------------------- REACT ------
// import * as React from 'react'
// import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// import {} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {    
//     SimpleUIAuthState,    
// } from './../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------

// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export const API_URL_PHONEZOLUTIONS = {
    CUSTOMERS: "/api/phonezolutions/customers/",
    RESELLERS: "/api/phonezolutions/resellers/",
    RECEIVERS: "/api/phonezolutions/receivers/"
}

export const API_URL_SIMPLECRM = {
    ENTITIES: "/api/simplecrm/entities/",
    TYPES: "/api/simplecrm/entitytypes/",
    USERTYPES: "/api/simplecrm/usertypes/",
    PERMISSIONGROUPS: "/api/simplecrm/permissiongroups/",
    DASHBOARDS: "/api/simplecrm/dashboards/",
}

export const functionalStateUpdate = (prevState, newState) => {    
        return {...prevState, ...newState}        
}

export const functionalStateUpdateKeyValuePair = (prevState, key, value) => {
    const newState = structuredClone(prevState)
    const path = key.split('.')
    const last = path.pop()
    path.reduce((ac,a) => {if (!ac[a]) ac[a] = {}; return ac[a]}, newState)[last] = value
    return newState
}


export const setStateKeyValuePair = (prevState, key, value) => {
    const newState = structuredClone(prevState)
    const path = key.split('.')
    const last = path.pop()
    path.reduce((ac,a) => {if (!ac[a]) ac[a] = {}; return ac[a]}, newState)[last] = value
    return newState
}


export const UserGroups = { 
    SIMPLEDATA_USERS: {
        id: "dadaf8d2-6864-47aa-b082-0ba84bb70e50",
        name: "SimpleData Users"
    },
    SIMPLEDATA_ADMINISTRATORS: {
        id: "25110e62-f495-4c55-9115-e102a05098bf",
        name: "SimpleData Administrators"
    }
}


export const userGroupIdAdministrator = "2a4707ae-ab17-47a7-b519-51f75a60f341"


export const isAdministrator = (user) => {
    return !!(user.usergroups.find(o => o.id === "7fa10ef2-f02a-4976-8638-93b4edcfa27f"))
}

export const isReseller = (user) => {
    return !!(user.usergroups.find(o => o.id === "926cfdc2-e655-412e-b1f5-4dd8ceb50e11"))
}

export const isCustomer = (user) => {
    return !!(user.usergroups.find(o => o.id === "1df86aa9-635a-4a74-8a37-46ffe573a450"))
}